import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1a45fba7 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _65fdb3dd = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _3a838214 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _d6ee71ea = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _9dc47ac8 = () => interopDefault(import('../pages/introduction.vue' /* webpackChunkName: "pages/introduction" */))
const _6da76bfe = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _a78fb09e = () => interopDefault(import('../pages/my/index.vue' /* webpackChunkName: "pages/my/index" */))
const _6e0424dd = () => interopDefault(import('../pages/notice/index.vue' /* webpackChunkName: "pages/notice/index" */))
const _9b1ce598 = () => interopDefault(import('../pages/private/index.vue' /* webpackChunkName: "pages/private/index" */))
const _765644c0 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _1e3f00ec = () => interopDefault(import('../pages/psych/index.vue' /* webpackChunkName: "pages/psych/index" */))
const _dd5ee4e2 = () => interopDefault(import('../pages/psyche/index.vue' /* webpackChunkName: "pages/psyche/index" */))
const _163ef306 = () => interopDefault(import('../pages/request.vue' /* webpackChunkName: "pages/request" */))
const _0064e6ad = () => interopDefault(import('../pages/roadmap/index.vue' /* webpackChunkName: "pages/roadmap/index" */))
const _08052b5c = () => interopDefault(import('../pages/schedule/index.vue' /* webpackChunkName: "pages/schedule/index" */))
const _9dc2a0a6 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _dfadee5a = () => interopDefault(import('../pages/teacher/index.vue' /* webpackChunkName: "pages/teacher/index" */))
const _2cf1f4dc = () => interopDefault(import('../pages/auth/leave.vue' /* webpackChunkName: "pages/auth/leave" */))
const _3e6303c8 = () => interopDefault(import('../pages/auth/leave-success.vue' /* webpackChunkName: "pages/auth/leave-success" */))
const _2d51984d = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _3a0ae7c4 = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _6c75062b = () => interopDefault(import('../pages/card/fail.vue' /* webpackChunkName: "pages/card/fail" */))
const _3c43566a = () => interopDefault(import('../pages/card/result.vue' /* webpackChunkName: "pages/card/result" */))
const _0767b79a = () => interopDefault(import('../pages/my/alarm/index.vue' /* webpackChunkName: "pages/my/alarm/index" */))
const _1c716f29 = () => interopDefault(import('../pages/my/authCode/index.vue' /* webpackChunkName: "pages/my/authCode/index" */))
const _558aa952 = () => interopDefault(import('../pages/my/coupon.vue' /* webpackChunkName: "pages/my/coupon" */))
const _0f72e939 = () => interopDefault(import('../pages/my/favorites/index.vue' /* webpackChunkName: "pages/my/favorites/index" */))
const _7d100d98 = () => interopDefault(import('../pages/my/friends.vue' /* webpackChunkName: "pages/my/friends" */))
const _e8ee683c = () => interopDefault(import('../pages/my/money/index.vue' /* webpackChunkName: "pages/my/money/index" */))
const _fd0c4e20 = () => interopDefault(import('../pages/my/order/index.vue' /* webpackChunkName: "pages/my/order/index" */))
const _3aea006a = () => interopDefault(import('../pages/my/profile/index.vue' /* webpackChunkName: "pages/my/profile/index" */))
const _2db0f886 = () => interopDefault(import('../pages/my/reviews/index.vue' /* webpackChunkName: "pages/my/reviews/index" */))
const _0d144611 = () => interopDefault(import('../pages/my/subscription/index.vue' /* webpackChunkName: "pages/my/subscription/index" */))
const _1a9b09cd = () => interopDefault(import('../pages/my/voucher.vue' /* webpackChunkName: "pages/my/voucher" */))
const _1eaf3589 = () => interopDefault(import('../pages/payment/fail.vue' /* webpackChunkName: "pages/payment/fail" */))
const _48af2648 = () => interopDefault(import('../pages/payment/result.vue' /* webpackChunkName: "pages/payment/result" */))
const _3459aa3a = () => interopDefault(import('../pages/private/banner.vue' /* webpackChunkName: "pages/private/banner" */))
const _553a2a12 = () => interopDefault(import('../pages/private/commonTextAreaField.vue' /* webpackChunkName: "pages/private/commonTextAreaField" */))
const _fa346f72 = () => interopDefault(import('../pages/private/complete.vue' /* webpackChunkName: "pages/private/complete" */))
const _b8e425b2 = () => interopDefault(import('../pages/private/preGuide.vue' /* webpackChunkName: "pages/private/preGuide" */))
const _5cbc7e7e = () => interopDefault(import('../pages/private/privateRequestForm.vue' /* webpackChunkName: "pages/private/privateRequestForm" */))
const _31631742 = () => interopDefault(import('../pages/private/processGuideModal.vue' /* webpackChunkName: "pages/private/processGuideModal" */))
const _770085d2 = () => interopDefault(import('../pages/product/suggest.vue' /* webpackChunkName: "pages/product/suggest" */))
const _482cd2ee = () => interopDefault(import('../pages/psyche/guidance/index.vue' /* webpackChunkName: "pages/psyche/guidance/index" */))
const _4e63c5b8 = () => interopDefault(import('../pages/psyche/huno/index.vue' /* webpackChunkName: "pages/psyche/huno/index" */))
const _20b6eaf7 = () => interopDefault(import('../pages/teacher/search.vue' /* webpackChunkName: "pages/teacher/search" */))
const _abd1db84 = () => interopDefault(import('../pages/my/money/fail.vue' /* webpackChunkName: "pages/my/money/fail" */))
const _56677623 = () => interopDefault(import('../pages/my/money/fill.vue' /* webpackChunkName: "pages/my/money/fill" */))
const _ccdaac86 = () => interopDefault(import('../pages/my/money/result.vue' /* webpackChunkName: "pages/my/money/result" */))
const _14896c2a = () => interopDefault(import('../pages/my/money/withdraw.vue' /* webpackChunkName: "pages/my/money/withdraw" */))
const _69334e50 = () => interopDefault(import('../pages/my/money/withdraw-result.vue' /* webpackChunkName: "pages/my/money/withdraw-result" */))
const _42126862 = () => interopDefault(import('../pages/my/order/cancel/index.vue' /* webpackChunkName: "pages/my/order/cancel/index" */))
const _0d009715 = () => interopDefault(import('../pages/my/profile/child.vue' /* webpackChunkName: "pages/my/profile/child" */))
const _363e878e = () => interopDefault(import('../pages/my/subscription/cancel/index.vue' /* webpackChunkName: "pages/my/subscription/cancel/index" */))
const _1c830f3f = () => interopDefault(import('../pages/my/subscription/confirm.vue' /* webpackChunkName: "pages/my/subscription/confirm" */))
const _10466448 = () => interopDefault(import('../pages/schedule/cancel/result.vue' /* webpackChunkName: "pages/schedule/cancel/result" */))
const _c20fbe80 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _96230fb0 = () => interopDefault(import('../pages/my/reviews/_id/index.vue' /* webpackChunkName: "pages/my/reviews/_id/index" */))
const _30de27fc = () => interopDefault(import('../pages/product/curation/_id.vue' /* webpackChunkName: "pages/product/curation/_id" */))
const _467631a6 = () => interopDefault(import('../pages/psyche/guidance/_slug.vue' /* webpackChunkName: "pages/psyche/guidance/_slug" */))
const _51d10848 = () => interopDefault(import('../pages/psyche/huno/_slug.vue' /* webpackChunkName: "pages/psyche/huno/_slug" */))
const _c63af392 = () => interopDefault(import('../pages/my/reviews/_id/result.vue' /* webpackChunkName: "pages/my/reviews/_id/result" */))
const _32a616e2 = () => interopDefault(import('../pages/my/order/_type/_id.vue' /* webpackChunkName: "pages/my/order/_type/_id" */))
const _188f5a5f = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _da5bb47a = () => interopDefault(import('../pages/event/_slug.vue' /* webpackChunkName: "pages/event/_slug" */))
const _530e0e06 = () => interopDefault(import('../pages/event/_slug/index.vue' /* webpackChunkName: "pages/event/_slug/index" */))
const _f1c08af6 = () => interopDefault(import('../pages/notice/_id.vue' /* webpackChunkName: "pages/notice/_id" */))
const _25846ff0 = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _3673aeea = () => interopDefault(import('../pages/product/_id/index.vue' /* webpackChunkName: "pages/product/_id/index" */))
const _5eee737f = () => interopDefault(import('../pages/product/_id/payment.vue' /* webpackChunkName: "pages/product/_id/payment" */))
const _b70c2efa = () => interopDefault(import('../pages/product/_id/subscribe.vue' /* webpackChunkName: "pages/product/_id/subscribe" */))
const _1c885fa4 = () => interopDefault(import('../pages/psych/_slug.vue' /* webpackChunkName: "pages/psych/_slug" */))
const _0f66b055 = () => interopDefault(import('../pages/roadmap/_id.vue' /* webpackChunkName: "pages/roadmap/_id" */))
const _57e0e318 = () => interopDefault(import('../pages/roadmap/_id/index.vue' /* webpackChunkName: "pages/roadmap/_id/index" */))
const _466176f7 = () => interopDefault(import('../pages/schedule/_hash.vue' /* webpackChunkName: "pages/schedule/_hash" */))
const _04f122ce = () => interopDefault(import('../pages/schedule/_hash/detail.vue' /* webpackChunkName: "pages/schedule/_hash/detail" */))
const _0c0db80a = () => interopDefault(import('../pages/teacher/_id.vue' /* webpackChunkName: "pages/teacher/_id" */))
const _45bce6f4 = () => interopDefault(import('../pages/coupon/_id/usable-product.vue' /* webpackChunkName: "pages/coupon/_id/usable-product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _1a45fba7,
    name: "blog"
  }, {
    path: "/chat",
    component: _65fdb3dd,
    name: "chat"
  }, {
    path: "/error",
    component: _3a838214,
    name: "error"
  }, {
    path: "/event",
    component: _d6ee71ea,
    name: "event"
  }, {
    path: "/introduction",
    component: _9dc47ac8,
    name: "introduction"
  }, {
    path: "/maintenance",
    component: _6da76bfe,
    name: "maintenance"
  }, {
    path: "/my",
    component: _a78fb09e,
    name: "my"
  }, {
    path: "/notice",
    component: _6e0424dd,
    name: "notice"
  }, {
    path: "/private",
    component: _9b1ce598,
    name: "private"
  }, {
    path: "/product",
    component: _765644c0,
    name: "product"
  }, {
    path: "/psych",
    component: _1e3f00ec,
    name: "psych"
  }, {
    path: "/psyche",
    component: _dd5ee4e2,
    name: "psyche"
  }, {
    path: "/request",
    component: _163ef306,
    name: "request"
  }, {
    path: "/roadmap",
    component: _0064e6ad,
    name: "roadmap"
  }, {
    path: "/schedule",
    component: _08052b5c,
    name: "schedule"
  }, {
    path: "/search",
    component: _9dc2a0a6,
    name: "search"
  }, {
    path: "/teacher",
    component: _dfadee5a,
    name: "teacher"
  }, {
    path: "/auth/leave",
    component: _2cf1f4dc,
    name: "auth-leave"
  }, {
    path: "/auth/leave-success",
    component: _3e6303c8,
    name: "auth-leave-success"
  }, {
    path: "/auth/signup",
    component: _2d51984d,
    name: "auth-signup"
  }, {
    path: "/auth/success",
    component: _3a0ae7c4,
    name: "auth-success"
  }, {
    path: "/card/fail",
    component: _6c75062b,
    name: "card-fail"
  }, {
    path: "/card/result",
    component: _3c43566a,
    name: "card-result"
  }, {
    path: "/my/alarm",
    component: _0767b79a,
    name: "my-alarm"
  }, {
    path: "/my/authCode",
    component: _1c716f29,
    name: "my-authCode"
  }, {
    path: "/my/coupon",
    component: _558aa952,
    name: "my-coupon"
  }, {
    path: "/my/favorites",
    component: _0f72e939,
    name: "my-favorites"
  }, {
    path: "/my/friends",
    component: _7d100d98,
    name: "my-friends"
  }, {
    path: "/my/money",
    component: _e8ee683c,
    name: "my-money"
  }, {
    path: "/my/order",
    component: _fd0c4e20,
    name: "my-order"
  }, {
    path: "/my/profile",
    component: _3aea006a,
    name: "my-profile"
  }, {
    path: "/my/reviews",
    component: _2db0f886,
    name: "my-reviews"
  }, {
    path: "/my/subscription",
    component: _0d144611,
    name: "my-subscription"
  }, {
    path: "/my/voucher",
    component: _1a9b09cd,
    name: "my-voucher"
  }, {
    path: "/payment/fail",
    component: _1eaf3589,
    name: "payment-fail"
  }, {
    path: "/payment/result",
    component: _48af2648,
    name: "payment-result"
  }, {
    path: "/private/banner",
    component: _3459aa3a,
    name: "private-banner"
  }, {
    path: "/private/commonTextAreaField",
    component: _553a2a12,
    name: "private-commonTextAreaField"
  }, {
    path: "/private/complete",
    component: _fa346f72,
    name: "private-complete"
  }, {
    path: "/private/preGuide",
    component: _b8e425b2,
    name: "private-preGuide"
  }, {
    path: "/private/privateRequestForm",
    component: _5cbc7e7e,
    name: "private-privateRequestForm"
  }, {
    path: "/private/processGuideModal",
    component: _31631742,
    name: "private-processGuideModal"
  }, {
    path: "/product/suggest",
    component: _770085d2,
    name: "product-suggest"
  }, {
    path: "/psyche/guidance",
    component: _482cd2ee,
    name: "psyche-guidance"
  }, {
    path: "/psyche/huno",
    component: _4e63c5b8,
    name: "psyche-huno"
  }, {
    path: "/teacher/search",
    component: _20b6eaf7,
    name: "teacher-search"
  }, {
    path: "/my/money/fail",
    component: _abd1db84,
    name: "my-money-fail"
  }, {
    path: "/my/money/fill",
    component: _56677623,
    name: "my-money-fill"
  }, {
    path: "/my/money/result",
    component: _ccdaac86,
    name: "my-money-result"
  }, {
    path: "/my/money/withdraw",
    component: _14896c2a,
    name: "my-money-withdraw"
  }, {
    path: "/my/money/withdraw-result",
    component: _69334e50,
    name: "my-money-withdraw-result"
  }, {
    path: "/my/order/cancel",
    component: _42126862,
    name: "my-order-cancel"
  }, {
    path: "/my/profile/child",
    component: _0d009715,
    name: "my-profile-child"
  }, {
    path: "/my/subscription/cancel",
    component: _363e878e,
    name: "my-subscription-cancel"
  }, {
    path: "/my/subscription/confirm",
    component: _1c830f3f,
    name: "my-subscription-confirm"
  }, {
    path: "/schedule/cancel/result",
    component: _10466448,
    name: "schedule-cancel-result"
  }, {
    path: "/",
    component: _c20fbe80,
    name: "index"
  }, {
    path: "/my/reviews/:id",
    component: _96230fb0,
    name: "my-reviews-id"
  }, {
    path: "/product/curation/:id?",
    component: _30de27fc,
    name: "product-curation-id"
  }, {
    path: "/psyche/guidance/:slug",
    component: _467631a6,
    name: "psyche-guidance-slug"
  }, {
    path: "/psyche/huno/:slug",
    component: _51d10848,
    name: "psyche-huno-slug"
  }, {
    path: "/my/reviews/:id/result",
    component: _c63af392,
    name: "my-reviews-id-result"
  }, {
    path: "/my/order/:type/:id?",
    component: _32a616e2,
    name: "my-order-type-id"
  }, {
    path: "/blog/:slug",
    component: _188f5a5f,
    name: "blog-slug"
  }, {
    path: "/event/:slug",
    component: _da5bb47a,
    children: [{
      path: "",
      component: _530e0e06,
      name: "event-slug"
    }]
  }, {
    path: "/notice/:id",
    component: _f1c08af6,
    name: "notice-id"
  }, {
    path: "/product/:id",
    component: _25846ff0,
    children: [{
      path: "",
      component: _3673aeea,
      name: "product-id"
    }, {
      path: "payment",
      component: _5eee737f,
      name: "product-id-payment"
    }, {
      path: "subscribe",
      component: _b70c2efa,
      name: "product-id-subscribe"
    }]
  }, {
    path: "/psych/:slug",
    component: _1c885fa4,
    name: "psych-slug"
  }, {
    path: "/roadmap/:id",
    component: _0f66b055,
    children: [{
      path: "",
      component: _57e0e318,
      name: "roadmap-id"
    }]
  }, {
    path: "/schedule/:hash",
    component: _466176f7,
    name: "schedule-hash",
    children: [{
      path: "detail",
      component: _04f122ce,
      name: "schedule-hash-detail"
    }]
  }, {
    path: "/teacher/:id",
    component: _0c0db80a,
    name: "teacher-id"
  }, {
    path: "/coupon/:id?/usable-product",
    component: _45bce6f4,
    name: "coupon-id-usable-product"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
